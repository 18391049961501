<template>
  <div>
    <section-banner
      v-for="(section, index) in sections"
      :key="index"
      :model="section"
    ></section-banner>
  </div>
</template>

<script>
import SectionBanner from "@/components/Frontend/SectionBanner";

export default {
  name: "Trusts",
  components: {
    SectionBanner,
  },
  data() {
    return {
      sections: [
        {
          Name: "What we do",
          Title: "Trusts",
          Subtitle: "Structuring and managing your assets",
          Description:
            "We have a wealth of experience in registering and administering a wide variety of trusts.",
          img: require("@/assets/img/Helanie.jpg"),
          justifyContentRight: true,
        },
        {
          TextColumn: true,
          Decoration: require("@/assets/img/Commas.svg"),
          TextColumn1: `<p>From start to finish, we provide all the services you’ll need, to ensure that your Trust is best tailored to your specific needs. <br></p>
            <p>We can assist with:<br></p>
            <ul>
              <li>Registering a Trust;</li>
              <li>The Administration of Trusts;</li>
              <li>Registration and implementation of BEE Trusts; and</li>
              <li>Registration and implementation of Non-profit Trusts.</li>
            </ul>`,
          TextColumn2:
            "<blockquote>On behalf of the South Deep Education Trust and South Deep Community Trust, I would like to commend the NLA Team for ensuring that our Trusts are administered in a manner that safeguards our assets and ensures that those assets are managed with full integrity and transparency while ensuring that tangible benefits accrue to beneficiary communities.<br><br>- Alex Khumalo, Chairperson, South Deep Education Trust and South Deep Community Trust</blockquote>",
        },
        {
          Profiles: true,
          Name: "Talk to Us",
          Bios: [
            {
              Title: "Natalie Lubbe",
              Subtitle: "Director",
              Linkedin: "linkedin.com/in/natalielubbe",
              Email: "natalie@NLAteam.com",
              Tel: "+27 11 704 1563",
              img: require("@/assets/img/Natalie-bio.jpg"),
              left: true,
            },
            {
              Title: "Helani van der Westhuizen",
              Subtitle: "Director",
              Linkedin: "linkedin.com/in/helani-van-der-westhuizen",
              Email: "helani@NLAteam.com",
              Tel: "+27 11 704 1563",
              img: require("@/assets/img/Helanie-bio.jpg"),
              left: false,
              last: true,
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
